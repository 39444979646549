import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Success.css";
import sriLogo from "../../Logo/Logo.png";
import Logout from "../../Logout/Logout";
import { isLoggedIn } from "../../Service/Authentication";


const Success = () => {

    // Number.prototype.toFixedNoRounding = function(n) {
    //     const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g")
    //     const a = this.toString().match(reg)[0];
    //     const dot = a.indexOf(".");
    //     if (dot === -1) { // integer, insert decimal dot and pad up zeros
    //         return a + "." + "0".repeat(n);
    //     }
    //     const b = n - (a.length - dot) + 1;
    //     return b > 0 ? (a + "0".repeat(b)) : a;
    //  }

    const navigate = useNavigate();
    const location = useLocation();
    
    const result12 = location.state?.res;
    console.log(result12)

    const skarat = result12?.karat_score;
    console.log(skarat)

    var Auscore = result12?.composition.au_score;
    Auscore = Auscore.toString();
    Auscore = Auscore.slice(0, (Auscore.indexOf("."))+2);
    Number(Auscore);
    console.log(Auscore)

    const simage = result12?.image;

    const goBack = () => {
        navigate("/camera")
    }
    useEffect(()=>{
        if(isLoggedIn() === false){
            navigate("/number")
        }
    })
    return (
        <>
            <div className="bac">
               
                <div className="success-image">
                    <div className="one"><img src={sriLogo} className="logo-size" /> </div>
                    <div className="four" > <span onClick={goBack} className="goback">Back</span>
                        <div className="five"><Logout /></div>
                    </div>
                    <div className="two"><img src={simage} className="success-image-inside" /></div>
                    <div className="three">
                        <div className="su">Success</div>
                        <span className="ss">{skarat} </span>
                        <span className="sk">Karat</span>
                    </div>
                    <div className="six">
                    <span className="au">Au</span><span style={{letterSpacing:"2px "}}> </span>
                    <span className="auscore"> {Auscore} %</span>
                      
                    </div>
                </div>
            </div>
        </>
    )
}
export default Success;