import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { useNavigate } from "react-router-dom";
import { getAccessToken, refreshAccessToken, isLoggedIn } from '../Service/Authentication';
// import Gallery from '../Gallery/Gallery';
import "./Camera.css";
import { clearAuthenticationTokens } from '../Service/Authentication';
import Logo from '../Logo/Logo';
import GoldRate from '../GoldRate/GoldRate';

const Camera = () => {
  const [spinner, setSpinner] = useState(false);
  const [buttonDisabling, setbuttonDisabling] = useState(true);
  const [image, setImage] = useState(null);
  const webcamRef = useRef(null);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn() === false) {
      navigate('/number')
    }
  })

  // const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";

  const videoConstraints = {

    facingMode: FACING_MODE_ENVIRONMENT
  };

  const logoutCameraPage = () => {
    clearAuthenticationTokens();
  }

  const handleCapture = async () => {

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }

      const mediaStreamTrack = stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(mediaStreamTrack);

      const capturedImageBlob = await imageCapture.takePhoto();
      setImage(capturedImageBlob);

      // Stop the media tracks when done
      stream.getTracks().forEach((track) => track.stop());

    } catch (error) {
      console.error('Error accessing the camera:', error);
    }
  };

  const handleUpload = async () => {
    setSpinner(true);
    setbuttonDisabling(true);
    if (image) {
      const formData = new FormData();
      formData.append('image', image, 'image.jpg');

      setSpinner(false);
      setbuttonDisabling(false);
      //const token = ; // Replace with your actual Bearer token
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/purity/v1/samples/submit`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: formData,
      });
      return response;
    }
  };

  const up = async () => {
    try {
      const response = await handleUpload();
      const responseData = await response.json();

      if (response.ok) {
        console.log('Image uploaded successfully!', response);
       
      }
      else if ((!response.ok) && (response.status == 401)) {
        //Check if response status code is 401 Then call refreshToken in Authentication file and again call the triggerApi()
        refreshAccessToken();
        // swal("please capture again")
      
      }
      else {
        console.error('Failed to upload the image.');
      }

      // console.log(img);
      const img = JSON.stringify(responseData.id);
      navigate("/karat", { state: { img } })
      // console.log(responseData.id);
      // console.log(responseData);
    } catch (error) {
      console.error('Error uploading the image:', error);
    }
  }

  const close = () => {
    setImage(false);
  }

  return (
    <>
      <Logo />
      <GoldRate />

      <div className='headd'>
        <div className='head'>
          <div   className='head1' onClick={()=>{
            navigate("/history")
          }}>History</div>

          <div className='head2' onClick={()=>{
            logoutCameraPage();
            navigate("/number")}} style={{ color: "black", textDecoration: "none" }}>Logout</div>
        </div>
      </div>
      <div className='wholepage'>
        <div className='container-head'>

          <div className='container1'>
            <div className="scan-line"> </div>
            <Webcam audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              className='video12' />
          </div>
          <video ref={videoRef} className='video23'></video>

          {spinner && (
            <div className="spinner-border" role="status" style={{ position: "absolute", left: "48%", top: "45%" }}>
              <span className="visually-hidden"  >Loading...</span>
            </div>
          )}
          {buttonDisabling && (
            <div className='button-head1'>
              <button onClick={() => {
                handleCapture();
              }} className='click' >Capture</button>
              <button onClick={close} className='close-button'>Retake</button>
              {image && <img src={URL.createObjectURL(image)} alt="Captured" className='image' />}
              {image && <button onClick={() => {
                up();
              
              }} className='upload'>Upload Image</button>
              }

            </div>
          )}

        </div>
      </div>
    </>
  );
}

export default Camera;
