import React from "react";
import { useNavigate } from "react-router-dom";
import "./Start.css";
import Stargif from "../Logo/sristar-gif.gif"

function Start(){
const navigate = useNavigate();
    setTimeout(()=>{
        navigate("/number")
    },4000)
    return(
        <>
        <div className="start-back">
            <div className="startlogo">
            <img src={Stargif} alt="sristar-logo" className="logosize"/>
            </div>
           
        </div>
        </>
    )
}
export default Start;