import React from "react";
import "./Logo.css";
import sristarlogo from "./Logo.png"

function Logo(){
    return(
        <>
        <div className="logo-background">
            <div className="logo">
            <img src={sristarlogo} alt="logo" className="size"/>
            </div>
        </div>
        </>
    )
}
export default Logo;