import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getAccessToken, refreshAccessToken, isLoggedIn } from '../Service/Authentication';
import "./Form.css";
import { useEffect } from 'react';
import Logo from '../Logo/Logo';
import GoldRate from '../GoldRate/GoldRate';

function Form() {
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn() === false) {
      navigate("/number")
    }
  })


  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSpinner(true);
    const result = formData;
    console.log(result)
    // Replace 'your_access_token' with the actual access token you obtained from the authentication process
    const accessToken = getAccessToken();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    };

    try {

      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/purity/v1/user/profile/update`, requestOptions);
      setSpinner(false);
      if (response.ok) {
        // Handle successful response from the backend
        console.log('Form data submitted successfully!', response);
      }
      else if ((!response.ok) && (response.status == 401)) {
        refreshAccessToken();
        response();
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Error submitting form data:', error);
    }
    navigate("/camera")
  };

  (function () {

    const forms = document.querySelectorAll('.requires-validation')
    Array.from(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event) {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          form.classList.add('was-validated')
        }, false)
      })
  })()

  return (
    <>
      <Logo />
      <GoldRate />
      <div className="form-body">
        <div className="row">
          <div className="form-holder">
            <div className="form-content">
              <div className="form-items">
                <h3>Register Today</h3>
                <p>Fill in the data below.</p>
                <form className="requires-validation" onSubmit={handleSubmit}>

                  <div className="col-md-12">
                    <input className="form-control"
                      placeholder="First Name"
                      type="text"
                      id="fname"
                      name="fname"
                      value={formData.fname}
                      onChange={handleChange}
                      required />

                  </div>

                  <div className="col-md-12">
                    <input className="form-control"
                      placeholder="Last Name"
                      type="text"
                      id="lname"
                      name="lname"
                      value={formData.lname}
                      onChange={handleChange}
                      required />
                  </div>

                  <div className="col-md-12">
                    <input
                      placeholder="email"
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required />
                  </div>

                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="invalidCheck" required />
                    <label className="form-check-label">I confirm that all data are correct</label>
                    <div className="invalid-feedback">Please confirm that the entered data are all correct!</div>
                  </div>

                  <div className="form-button mt-3">
                    <button id="submit" type="submit" className="btn btn-primary" style={{ backgroundColor: "purple", border: "1px solid purple" }}>Register</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {spinner && (
        <div className="spinner-border" role="status" style={{ marginLeft: "48%", marginRight: "auto", marginTop: "10px" }}>
          <span className="visually-hidden"  >Loading...</span>
        </div>
      )}
    </>
  )
}
export default Form;

