import React from 'react';
import RouterComponent from './Utility/RouterComponent';

function App() {
  
  return (
    <div className='App'>
      <RouterComponent />
    </div>
  );
}

export default App;