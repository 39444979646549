import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAccessToken, isLoggedIn, refreshAccessToken } from "../Service/Authentication";
import Logo from "../Logo/Logo";
import "./Karat.css";
import Loader from "./Loader/Loader";
import GoldRate from "../GoldRate/GoldRate";
import swal from 'sweetalert';

const Karat = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const id = location.state?.img;
  // console.log(id)
  const [data, setData] = useState({});
  var [res, setres] = useState('');
  var [scoredata, setscoreData] = useState('')
  var [image_Status, setimage_Status] = useState('')
  var [Auscore, setAuscore] = useState('')
  //const condition = "some_condition"; // Replace with your specific condition

  const fetchCarat = async () => {
    await fetch(`${process.env.REACT_APP_BACKEND_HOST}/purity/v1/samples/reports/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      },
    })
      .then(response =>
        response.json()
      )
      .then((response) => {
        setData(response)
        // console.log(response);
        if ((!response.ok) && (response.status == 401)) {
          refreshAccessToken();
        }
        // console.log( response)
        const resdata = response;
        setres(res = resdata);
        const score = response.karat_score;
        setscoreData(scoredata = score)
        // console.log(scoredata);

        const retakevalue = response.image_status;
        setimage_Status(image_Status = retakevalue);
        // console.log(blur);

        const Ausrc = response.composition.au_score;
        setAuscore(Auscore = Ausrc);
        // console.log(Auscore);

        const ans = JSON.stringify(response);
        // console.log(ans);

      })
      .catch((error) => {
        console.error('Error:', error);
      });

  };

  useEffect(() => {

    if (isLoggedIn() === false) {
      navigate('/number')
    }
    else {
      fetchCarat();
    }
    // Fetch data initially when the component mounts
    // Fetch data every 10 seconds based on the condition
    const interval = setInterval(() => {
      // console.log(scoredata)

      if ((image_Status === "NOT_VISIBLE")) {
        clearInterval(interval);
        swal("Blur Image!", "Please capture again");
        navigate("/camera")
      }
      else if((image_Status === "FRAUD_ENTITY")){
        clearInterval(interval);
        swal("Spurious Gold!", "Please capture again");
        navigate("/camera")
      }
      else if (image_Status === 'TEMPORARY') {
        fetchCarat();
      }
      else {
        clearInterval(interval);
        navigate("/success", { state: { res, Auscore } })
      }
    }, 10000); // 10000ms = 10 seconds    
  }, []);

  const goBack = () => {
    navigate("/camera")
  }

  return (
    <div>
      <Logo />
      <GoldRate />
      <div className="flex">
        <div className="flex1" onClick={goBack}>Back</div>
        <div className="flex2" ><a href={`${process.env.REACT_APP_DOMAIN_NAME}/history`} className='head1' style={{ textDecoration: "none" }}>History</a></div>
      </div>
      <div className="gold-scanner">
        <img src={data.image} alt="gold-Image" className="gold-image" />
      </div>
      <Loader />
    </div>
  );
};
export default Karat;


