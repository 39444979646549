const accessToken = 'accessToken';
const refreshToken = 'refreshToken';
const fcmToken = 'fcmToken';


export function refreshAccessToken (){
    var refreshToken = localStorage.getItem('refreshToken');
    clearAuthenticationTokens();
    fetch(`${process.env.REACT_APP_BACKEND_HOST}/purity/v1/auth/token/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    }).then((response) => {
      if(!response.ok) throw new Error(response.status);
      else return response.json();
    }).then((data) => {
      setAuthenticationTokens(data.access, data.refresh)
      window.location.reload();
    })
    .catch((error) => {
      console.log('error: ' + error);
      clearAuthenticationTokens();
      window.location.reload();
    });
};

export function getAccessToken() {
  // Implement your logic to get the refresh token
  // For this example, let's assume we have a refresh token stored in local storage.
  return localStorage.getItem(accessToken);
};

export function getFcmTokenFromLocalStorage() {
  // Implement your logic to get the refresh token
  // For this example, let's assume we have a refresh token stored in local storage.
  return localStorage.getItem(fcmToken);
};

export function  setFcmTokenToLocalStorage(fcm_token) {
  localStorage.setItem(fcmToken, fcm_token);
}

// Setting access and refresh token to local storage.
export function  setAuthenticationTokens(access_token, refresh_token) {
  console.log("New token", access_token, " fdfdf ", refresh_token)
  localStorage.setItem(accessToken, access_token);
  localStorage.setItem(refreshToken, refresh_token);
}

export function clearFcmToken(){
  localStorage.removeItem(fcmToken);
}

export function clearAuthenticationTokens(){
  localStorage.removeItem(accessToken);
  localStorage.removeItem(refreshToken);
}

export function isLoggedIn(){
  if (!localStorage.getItem(accessToken) || !localStorage.getItem(refreshToken)){
    clearAuthenticationTokens();
    return false;
  }
return true;
}