import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../Service/Authentication';
import Logo from "../Logo/Logo"
import GoldRate from '../GoldRate/GoldRate';
import { useLocation } from 'react-router-dom';
import {getFcmToken, onMessageListener} from '../Service/firebase';
import { getAccessToken , getFcmTokenFromLocalStorage, setFcmTokenToLocalStorage} from '../Service/Authentication';
import { useSearchParams } from "react-router-dom";
import {Button, Row, Col, Toast} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';



const PurityTeam = () => {
    const [searchParams, setSearchParams] = useSearchParams();
   
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});
    const [tokenStateObj, tokenState] = useState({tokenFound: false, fcmTokenValue: ''});

    const source = "https://sristar.org/purity-team";

    onMessageListener().then(payload => {
        setShow(true);
        setNotification({title: payload.notification.title, body: payload.notification.body})
        console.log(payload);
    }).catch(err => console.log('failed: ', err));

    const pushFcmTokenToBackend = async () => {
        var token = tokenState.fcmTokenValue;

        if (token != getFcmTokenFromLocalStorage()){
            console.log('Sending fcm token to backend');

            setSpinner(true);
            await fetch(`${process.env.REACT_APP_BACKEND_HOST}/purity/v1/user/fcm/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getAccessToken()}`,
                },
                body: JSON.stringify({ token, source }),
            })
                .then((response) => {
                    setSpinner(false);
                    response.json();
                    console.log(response);
                    setFcmTokenToLocalStorage(token);
                    navigate("/camera")
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }

        
    }
   
    useEffect(() => {

        var redirect_url = searchParams.get("redirect");
        if (redirect_url)
            window.location.replace(redirect_url);

        if(isLoggedIn() == false){
            navigate("/number")
        }

        async function generateFcmToken() {
            await getFcmToken(tokenState);
            pushFcmTokenToBackend();
        }

        generateFcmToken(); 
        


    }, [])

    return (
        
        <div className="App">
            <Logo />
            <GoldRate />
            
        <header className="App-header">
            {tokenStateObj && <h1> Notification permission enabled 👍🏻 </h1>}
            {!tokenStateObj && <h1> Need notification permission ❗️ </h1>}
            
        </header>
        </div>
    );
}
export default PurityTeam;