import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyCWH2RHXqQQP4UI-RFe4RVRhioVRkz9ExE",
    authDomain: "newsbackend-388608.firebaseapp.com",
    projectId: "newsbackend-388608",
    storageBucket: "newsbackend-388608.appspot.com",
    messagingSenderId: "276859191695",
    appId: "1:276859191695:web:fff18c14124b22a093cbfa"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export const getFcmToken = async (tokenState) => {
    return getToken(messaging, {vapidKey: 'BMWKNH3gknmLgeygm4NAIb4mfbDEe0Na5Ydd9gnsv715-lrVyzGxyZTTp__1r-DQRd4cimHc6t9vE35DLUpDZaQ'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        tokenState.tokenFound = true;
        tokenState.fcmTokenValue= currentToken;
        console.log(tokenState.fcmTokenValue)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        tokenState.tokenFound = false;
        tokenState.fcmTokenValue= '';

        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }

  