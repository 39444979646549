import React from "react";
import "./Loader.css";

function Loader() {

    return (
        <>
            <div className="outerbox">
                <div className="loader">
                    <div className="loading_1">
                    </div>
                    <div className="loading_2">Testing Purity...</div>
                </div>
            </div>
        </>
    )
}
export default Loader;