import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { setAuthenticationTokens, isLoggedIn } from "../Service/Authentication";
import "./Otp.css";
import Logo from '../Logo/Logo';
import GoldRate from '../GoldRate/GoldRate';

function Otp() {
  const [spinner, setSpinner] = useState(false);
  const [code, setOtp] = useState('');
  const location = useLocation();

  const handleChange = (e) => {
    setOtp(e.target.value);
  };
  const navigate = useNavigate();

  const phone_number = location.state?.phone_number;

  const handleSubmit = async (e) => {

    e.preventDefault();
    setSpinner(true);

    try {

      // Call the backend API to verify OTP and get the access token
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/purity/v1/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number, code }),
      });
      setSpinner(false);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to verify OTP');
      }

      setAuthenticationTokens(data.tokens.access, data.tokens.refresh);

    } catch (error) {
      console.error('Failed to verify OTP:', error.message);
    }
    navigate("/spinner")
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    setSpinner(true);

    await fetch(`${process.env.REACT_APP_BACKEND_HOST}/purity/v1/auth/generate-otp?phone_number=${phone_number}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        setSpinner(false);
        response.json()
        // console.log(response);
      }
      )
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    if (isLoggedIn() === true) {
      navigate('/camera')
    }

  })
  return (
    <>
      <Logo />
      <GoldRate />
      <div className="d-flex justify-content-center align-items-center container">
        <div className="card py-5 px-3">
          <h5 className="m-0">Mobile phone verification</h5>
          <span className="mobile-text">
            Enter the code we just send on your mobile phone
            <b className="text-danger">+91 {phone_number}</b>
          </span>
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-row mt-5">
              <input type="text"
                className="form-control"
                placeholder="Enter otp number"
                value={code}
                onChange={handleChange}
                style={{ borderRadius: "20px" }}
              />
            </div>
            <div className="text-center mt-5">
              <span>
                <button className="submit" type="submit" value="submit" style={{ color: "white", borderRadius: "20px", fontWeight: "500", border: "none", fontSize: "larger", backgroundColor: "#470249", paddingRight: "10px", paddingLeft: "10px" }}>SUBMIT</button>
              </span>
              <span className="d-block mobile-text">
                Don't receive the code?
              </span>

              <span className="font-weight-bold text-danger cursor">

                <button onClick={resendOtp} className="resend" style={{ color: "white", borderRadius: "20px", fontWeight: "500", border: "none", fontSize: "larger", backgroundColor: "#470249", paddingRight: "10px", paddingLeft: "10px", paddingBottom: "4px" }}>Resend</button>
              </span>
            </div>
          </form>

        </div>
      </div>
      {spinner && (
        <div className="spinner-border" role="status" style={{ marginLeft: "48%", marginRight: "auto", marginTop: "40px" }}>
          <span className="visually-hidden"  >Loading...</span>
        </div>
      )}
    </>
  )
}
export default Otp;