import React, { useEffect } from 'react';
import { useState } from 'react';
import "./PhoneNumber.css"
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../Service/Authentication';
import Logo from "../Logo/Logo"
import GoldRate from '../GoldRate/GoldRate';

const PhoneNumber = () => {

    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(false);
    const [phone_number, setphone_number] = useState('');
    const handleMobileNumberChange = (event) => {
        setphone_number(event.target.value);
    };

    const generateOtp = async () => {
        setSpinner(true);
        await fetch(`${process.env.REACT_APP_BACKEND_HOST}/purity/v1/auth/generate-otp?phone_number=${phone_number}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                setSpinner(false);
                response.json()
                // console.log(response);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        navigate("/otp", { state: { phone_number } })

    };
    useEffect(() => {
        if (isLoggedIn() === true) {
            navigate('/spinner')
        }
        else {
            navigate("/number")
        }
    }, [])

    return (
        <div>
            <Logo />
            <GoldRate />
            <div className='box'>
                <div className='heading'>Enter Mobile Number</div>
                <br></br>
                <label className='number-box'>
                    <input
                        type="text"
                        value={phone_number}
                        onChange={handleMobileNumberChange}
                        placeholder="Enter mobile number"
                    />
                </label><br />
                <button className="button" onClick={generateOtp}
                >GENERATE OTP</button>
            </div>
            {spinner && (
                <div className="spinner-border" role="status" style={{ marginLeft: "48%", marginRight: "auto", marginTop: "20px" }}>
                    <span className="visually-hidden"  >Loading...</span>
                </div>
            )}
        </div>
    )
}
export default PhoneNumber;