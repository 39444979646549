import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PhoneNumber from "../MobileNumber/PhoneNumber";
import Otp from "../OTP/Otp";
import Form from "../UserForm/Form";
import Camera from "../Picture/Camera";
import History from "../History/History";
import Spinner from "../Spinner/Spinner";
import Start from "../Startpage/Start";
import Karat from "../Picture/Karat";
import Success from "../Picture/Success/Success";
import PurityTeam from "../PurityTeam/PurityTeamFCM";

function RouterComponent() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Start />} />
        <Route path="/number" exact element={<PhoneNumber />} />
        <Route path="/otp" exact element={<Otp />} />
        <Route path="/spinner" exact element={<Spinner />} />
        <Route path="/form" exact element={<Form />} />
        <Route path="/camera" exact element={<Camera />} />
        <Route path="/karat" exact element={<Karat />} />
        <Route path="/history" exact element={<History />} />
        <Route path="/success" exact element={<Success />} />
        <Route path="/purity-team" exact element={<PurityTeam />} />
      </Routes>
    </BrowserRouter>
  )
}

export default RouterComponent;