import React from "react";
import { clearAuthenticationTokens } from "../Service/Authentication";
import { useNavigate } from "react-router-dom";


function Logout() {
    const navigate = useNavigate()
    const logoutOption = () => {
        clearAuthenticationTokens();
        navigate("/number")
    }
    return (
        <>
            <div onClick={logoutOption} 
            style={{
                color:"black",
                fontSize:"larger",
                fontWeight:500,
                border:"none",
                cursor:"pointer"
            }}>Logout</div>
        </>
    )
}
export default Logout; 