import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAccessToken, refreshAccessToken, isLoggedIn } from "../Service/Authentication"
import Logo from '../Logo/Logo';

const Spinner = () => {

  const navigate = useNavigate();

  if (isLoggedIn() === true) {
    navigate('/camera')
  }
  else {
    navigate('/number')
  }
  
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  // Call the fetchData function when the component mounts or when accessToken changes


  const triggerApi = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/purity/v1/user/profile/get`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`
      },
    });
    return response;
  }

  // Function to fetch data from the backend API using the access token
  const fetchData = async () => {
    try {
      const response = await triggerApi();
      setData(response)
      // console.log(response);
       if ((!response.ok) && (response.status == 401)) {
        //Check if response status code is 401 Then call refreshToken in Authentication file and again call the triggerApi()
                refreshAccessToken();    
      }
      const responseData = await response.json();
      // console.log(responseData.profile_pending)
      //setData(responseData)
      setTimeout(() => {
        if (responseData.profile_pending === true) {
          navigate("/form");
        }
        else {
          navigate("/camera")
        }
      }, 4000)

    } catch (error) {
      setError(error.message);
    }
  };
  

  useEffect(() => {
   if(isLoggedIn() === false){
    navigate("/number")
   }
   else{
    fetchData();
   }
     }, []);

  return (
    <div>
      <Logo />
      <div className='load-text' style={{ fontWeight: 500, fontSize: "larger", textAlign: "center", marginTop: "30px" }}>Loading...</div>
      <div className="spinner-border" role="status" style={{ marginLeft: "48%", marginTop: "2%" }}>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;

