import React from "react";
import "./GoldRate.css"

function GoldRate(){
    return(
        <>
        <div className="background-color-goldRate">
            Today's Gold Rate: Karnataka - xxxx | Tamil Nadu - xxxx
        </div>
        </>
    )
}
export default GoldRate;