import React, { useState, useEffect } from 'react';
import { getAccessToken, refreshAccessToken, isLoggedIn } from '../Service/Authentication';
import { useNavigate } from 'react-router-dom';
import "./History.css"
import Logout from '../Logout/Logout';
import Logo from '../Logo/Logo';
import GoldRate from '../GoldRate/GoldRate';

const History = () => {

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const backendApiUrl = `${process.env.REACT_APP_BACKEND_HOST}/purity/v1/samples/reports`; // Replace with your backend API endpoint

  // const {login} = isLoggedIn();
     
  // Function to fetch data from the backend API
  const fetchData = async () => {
   try {
          // Set the Authorization header with the access token
     const headers = {
       Authorization: `Bearer ${getAccessToken()}`,
     };
     
     // Make the API call to the backend
     const response = await fetch(backendApiUrl, {
       headers, 
     });
    
     if (response.ok) {
       // Convert the response to JSON format
       const responseData = await response.json();
       setData(responseData);
     }
     else if ((!response.ok) && (response.status == 401)) {
       //Check if response status code is 401 Then call refreshToken in Authentication file and again call the triggerApi()
       
          refreshAccessToken();
       
     }
     else {
       // Handle error
       console.error('Error fetching data:', response.statusText);
     }
   } catch (error) {
     console.error('Error:', error);
   }
 };


  useEffect(() => {
    if (isLoggedIn() == false) {
      // throw new Error('User is not logged in.');
      navigate("/number")
    } else{
      fetchData();
    }
    // Call the fetchData function when the component mounts
   
  }, []); // Empty dependency array ensures the effect runs only once
  const goBack = () => {
    navigate("/camera")
  }
  return (
    <>
      <div >
        <Logo />
        <GoldRate/>
        <h2 style={{ textAlign: "center" }}>Previous Submissions</h2>
        <div className='button-head'>
          <div onClick={goBack} className='back'>Back</div>
          <div className='logout'><Logout /></div>
        </div>

        <div class="m-4">
          <table class="table table-hover">
            <thead style={{textAlign:"center", backgroundColor:"black", color:"white"}}>
              <tr>

                <th>Image</th>
                <th>Date & Time</th>
                <th>Karat Score</th>
                <th>Au Score</th>
              </tr>
            </thead>
            <tbody style={{textAlign:"center"}}>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                   <img src={item.image} alt="image" className="picsize" /> 
                  </td>
                  <td style={{paddingTop:"38px", paddingBottom:"auto"}}>{item.created_at}</td>
                  <td style={{paddingTop:"38px", paddingBottom:"auto"}}>{item.karat_score}</td>
                  <td style={{paddingTop:"38px", paddingBottom:"auto"}}>{item.composition.au_score}%</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>

      </div>
    </>
  )
}

export default History;

{/* <table style={{ marginLeft: "auto", width: "100%", marginRight: "auto", marginTop: "10px", borderSpacing: "0 15px;", border: "1px solid black", textAlign: "center", cellpacing: "5" }} >
  <thead>
    <tr >
      <th>Image</th>
      <th style={{ border: "1px solid black" }}>Date & Time</th>
      <th>Karat</th>

    </tr>
  </thead>
  <tbody >
    {data.map((item, index) => (
      <tr key={index} className='tr'
        style={{ border: "1px solid black", borderRadius: "25px", borderSpacing: "5px" }}>
        <td>
          <img src={item.image} alt="image" className="picsize" />
        </td>

        <td style={{ border: "1px solid black" }}><span style={{ fontWeight: 500 }}>Submitted On: </span>{item.created_at}</td>
        <td><span style={{ fontWeight: 500 }}> Karat Score: </span>{item.karat_score}</td>


      </tr>
    ))}
  </tbody>
</table>  */}